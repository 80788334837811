const getVar = ({ root = null, name = [] }) => {
  const variables = {};
  const style = !!root ? getComputedStyle(root) : getComputedStyle(document.documentElement);
  const _name = Array.isArray(name) ? name : [name];
  _name.forEach((cssVar) => {
    const parseName = cssVar.startsWith("--") ? name : `--${cssVar}`;
    const value = style.getPropertyValue(parseName).trim().replace(/['"]+/g, "");
    const numericValue = value.match(/\d+/g);
    variables[cssVar] = {
      cssName: parseName,
      cssValue: value,
      value: !!numericValue ? parseInt(numericValue, 10) : value,
      unit: !!numericValue ? value.split(numericValue[0])[1] : null
    };
  });
  return variables;
};
const setCSSVar = ({ element = document.documentElement, name, value }) => {
  return element.style.setProperty(name.trim(), value);
};
const removeCSSVar = ({ element = document.documentElement, name }) => {
  return element.style.removeProperty(`${name}`);
};
export {
  getVar as g,
  removeCSSVar as r,
  setCSSVar as s
};
